




















import { defineComponent, ref } from "@vue/composition-api";
import { toastController } from "@/composables/useToast";
import { useImportAPI } from "@/api-connectors/useImportAPI";

export default defineComponent({
    name: "ImportExternalTransactions",
    setup() {
        const { importTransactions } = useImportAPI()

        const fileInput = ref<HTMLInputElement>()

        const uploadTransactionsExcel = (): void => {

            const item = fileInput.value?.files?.item(0)
            if (!item) return;

            const reader = new FileReader();

            reader.onload = async (readResult) => {
                if (!readResult?.target) return;

                const data = (readResult.target.result as string).split(',')[1];
                const response = await importTransactions(data)

                if (!response) return toastController.toast('Transaktionen konnten nicht hochgeladen werden.', {
                    title: 'Hochladen fehlgeschlagen',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    solid: true
                })

                toastController.toast(`Transaktionen wurden erfolgreich hochgeladen`, {
                    title: 'Hochladen erfolgreich',
                    autoHideDelay: 3000,
                    variant: 'success',
                    solid: true
                })

                if (!fileInput.value) return;
                fileInput.value.value = ''
                fileInput.value.files = null;
            }

            reader.onerror = () => {
                toastController.toast('Datei konnte nicht gelesen werden.', {
                    title: 'Hochladen fehlgeschlagen',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    solid: true
                })
            }

            if (!item.name.includes('.csv')) return toastController.toast('Datei muss vom Typ .csv sein.', {
                title: 'Hochladen fehlgeschlagen',
                autoHideDelay: 3000,
                variant: 'danger',
                solid: true
            })

            reader.readAsDataURL(item);
        }

        return {
            fileInput,
            uploadTransactionsExcel
        }
    }
})
