import { useAPI } from "@/composables/useAPI";

export function useImportAPI() {
    const { api } = useAPI()

    const importTransactions = async (data: string) => {
        return await api.post('externalTransaction/import', { data: data })
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    return {
        importTransactions
    }
}
